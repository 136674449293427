import { SkeletonChallengeGridCard } from './ChallengeGridCard.skeleton';

export const SkeletonChallengeGrid = (): JSX.Element => {
  return (
    <section className="skeleton-challenge-grid">
      <div className="skeleton-challenge-grid--title">
        <div className="skeleton-challenge-grid--title__item" />
        <div className="skeleton-challenge-grid--title__item mobile" />
        <div className="skeleton-challenge-grid--title__item mobile reduced" />
      </div>
      <SkeletonChallengeGridCard />
      <SkeletonChallengeGridCard />
      <SkeletonChallengeGridCard />
    </section>
  );
};
