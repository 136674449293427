import { FC, useEffect, useState } from 'react';
import { SkeletonMedia } from './Media.skeleton';
import SkeletonDescription from './Description.skeleton';

interface SkeletonPublicCardImageProps {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
}

const SkeletonPublicCardImage: FC<SkeletonPublicCardImageProps> = ({
  leftContent,
  rightContent,
}): JSX.Element => {
  const { button, titleWrappedRows, custom } =
    leftContent.props || rightContent.props;

  const WRAP_LIMIT = 1250;
  const [isWrapped, setIsWrapped] = useState<boolean>(true);

  useEffect(() => {
    const handleResize = () => {
      setIsWrapped(window.innerWidth < WRAP_LIMIT);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const mobileLeftContent = <SkeletonMedia />;
  const mobileRightContent = (
    <SkeletonDescription
      rows={3}
      titleWrappedRows={titleWrappedRows ?? 1}
      button={button}
      custom={custom}
    />
  );

  return isWrapped ? (
    <div className="skeleton-publicCardImage">
      <div className="skeleton-cardImage--left">{mobileLeftContent}</div>
      <div className="skeleton-cardImage--right">{mobileRightContent}</div>
    </div>
  ) : (
    <div className="skeleton-publicCardImage">
      <div className="skeleton-cardImage--left">{leftContent}</div>
      <div className="skeleton-cardImage--right">{rightContent}</div>
    </div>
  );
};

export default SkeletonPublicCardImage;
