import { NextPage } from 'next';
import SkeletonDescription from '../Description.skeleton';
import { SkeletonMedia } from '../Media.skeleton';
import { SkeletonChallengeGrid } from '../ChallengeGrid.skeleton';
import { SkeletonCustomCard } from '../CustomCard.skeleton';
import { SkeletonMainContent } from '../MainContent.skeleton';
import SkeletonPublicCardImage from '../PublicCardImage.skeleton';

const PublicHomeSkeleton: NextPage = (): JSX.Element => {
  return (
    <section className="publicHome-skeleton">
      <div className="skeleton-banner" />
      <SkeletonMainContent />
      <SkeletonPublicCardImage
        leftContent={<SkeletonDescription rows={3} titleWrappedRows={2} />}
        rightContent={<SkeletonMedia />}
      />
      <SkeletonPublicCardImage
        leftContent={<SkeletonMedia />}
        rightContent={<SkeletonDescription rows={3} />}
      />
      <SkeletonPublicCardImage
        leftContent={<SkeletonDescription rows={5} custom={'reversed'} />}
        rightContent={<SkeletonMedia />}
      />
      <SkeletonChallengeGrid />
      <SkeletonCustomCard />
    </section>
  );
};

export default PublicHomeSkeleton;
