import { FC } from 'react';
import { SkeletonJoinButton } from './JoinButton.skeleton';

interface SkeletonDescriptionProps {
  rows?: number;
  titleWrappedRows?: number;
  button?: boolean;
  custom?: string;
}

const SkeletonCardDescription: FC<SkeletonDescriptionProps> = ({
  rows = 3,
  titleWrappedRows = 1,
  button = false,
  custom = '',
}): JSX.Element => {
  const titleElements = [<></>];
  titleElements.push(
    <div className={`skeleton-cardDescription--title ${custom}`} />
  );
  for (let i = 1; i <= titleWrappedRows; i++) {
    titleElements.push(
      <div className={`skeleton-cardDescription--title mobile ${custom}`} />
    );
  }
  titleElements.push(
    <div className="skeleton-cardDescription--title reduced reversed" />
  );

  const descriptionElements = [<></>];
  for (let i = 1; i < rows; i++) {
    descriptionElements.push(
      <div className={`skeleton-cardDescription--description`} />
    );
  }
  descriptionElements.push(
    <div className={`skeleton-cardDescription--description reduced`} />
  );

  return (
    <div className="skeleton-cardDescription">
      {titleElements.map((element) => element)}
      {descriptionElements.map((element) => element)}
      {button ? (
        <div className="skeleton-cardDescription--button">
          <SkeletonJoinButton />
        </div>
      ) : null}
    </div>
  );
};

export default SkeletonCardDescription;
