export const SkeletonChallengeGridCard = (): JSX.Element => {
  return (
    <section className="skeleton-challenge-grid-card">
      <div className="skeleton-challenge-grid-card--media" />
      <div className="skeleton-challenge-grid-card--title" />
      <div className="skeleton-challenge-grid-card--description">
        <div className="skeleton-challenge-grid-card--description__item" />
        <div className="skeleton-challenge-grid-card--description__item" />
        <div className="skeleton-challenge-grid-card--description__item" />
      </div>
    </section>
  );
};
