import { SkeletonJoinButton } from './JoinButton.skeleton';
import { SkeletonMedia } from './Media.skeleton';

export const SkeletonCustomCard = (): JSX.Element => {
  return (
    <section className="skeleton-customCard">
      <SkeletonMedia />
      <div className="skeleton-customCard-descriptionCard">
        <div className="skeleton-customCard--title" />
        <div className="skeleton-customCard-descriptionContainer">
          <div className="skeleton-customCard--description" />
          <div className="skeleton-customCard--description mobile" />
          <div className="skeleton-customCard--description" />
        </div>
        <div className="skeleton-cardDescription--button">
          <SkeletonJoinButton />
        </div>
      </div>
    </section>
  );
};
