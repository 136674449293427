import { SkeletonStep } from './Step.skeleton';

export const SkeletonMainContent = (): JSX.Element => {
  return (
    <section className="skeleton-mainContent">
      <div className="skeleton-mainContent--logo" />
      <div className="skeleton-mainContent--title">
        <div className="skeleton-mainContent--title-item" />
        <div className="skeleton-mainContent--title-item reduced" />
        <div className="skeleton-mainContent--title-item mobile" />
      </div>
      <div className="skeleton-mainContent--steps">
        <SkeletonStep />
        <SkeletonStep />
        <SkeletonStep />
      </div>
      <div className="skeleton-mainContent--button" />
      <div className="skeleton-mainContent--link" />
    </section>
  );
};
